
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p.page-info {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 13px;
    color: #000;
  }
  .paginator {
    display: flex;
    align-items: center;
    gap: 0px;
    span {
      user-select: none;
      color: #000;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      width: 23px;
      height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 700;
      font-size: 10.5px;
      line-height: 15px;
      cursor: pointer;
      margin-right: 8px;
      &.active {
        border: 1px solid #fa8c28;
        color: #fa8c28;
        &.disabled {
          background: #eeeeee;
          color: #000;
          border: none;
          pointer-events: none;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
    p.page-info {
      margin-top: 16px;
      text-align: center;
    }
    .paginator {
      width: fit-content;
      margin: 12px auto 0;
    }
  }
}
